<template>
    <div>
        <my-no-data v-if="datalist.length == 0" conentTop="50%"></my-no-data>
        <div class="formlist">
            <div class="list" v-for="item in datalist">
                <!-- <img :src="item.imageUrl" alt=""> -->
                <img src="../../assets/image/home/back1.png" alt="">
                <div class="msg flex ">
                    <div class="msg-father flex wrap">
                        <div>分类：{{ item.classification }}</div>
                        <div>属：{{ item.genericCharacters }}</div>
                        <div>时代：{{ item.ageTo }}</div>
                    </div>
                    <div class="msg-son flex wrap">
                        <span v-for="item2 in getmsg(item)">
                            {{ item2.name }}：{{ item2.value }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="page">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import formApi from '@/api/form/index';
export default {
    data() {
        return {
            total: 0,
            option: {},
            datalist: []
        }
    },
    computed: {
        ...mapState(['formList']),
    },
    methods: {
        getmsg(data) {
            let list = []
            // 菌类孢子
            if (data.classification == "菌类孢子") {
                list = [
                    {
                        name: "离散形式",
                        nameEn: "Discrete version",
                        value: data.formDetails.discreteVersion
                    },
                    {
                        name: "形状",
                        nameEn: "shape",
                        value: data.formDetails.shape
                    },
                    {
                        name: "大小",
                        nameEn: "size",
                        value: data.formDetails.size
                    },
                    {
                        name: "轮廓线",
                        nameEn: "",
                        value: data.formDetails.contourLine
                    },
                    {
                        name: "外壁厚度",
                        nameEn: "",
                        value: data.formDetails.outerWallThickness
                    },
                    {
                        name: "厚度 (微米)",
                        nameEn: "",
                        value: data.formDetails.thickness
                    },
                    {
                        name: "表面纹饰",
                        nameEn: "",
                        value: data.formDetails.surfaceOrnamentation
                    },
                    {
                        name: "萌发口器",
                        nameEn: "aperture",
                        value: data.formDetails.aperture
                    },
                    {
                        name: "孔数量",
                        nameEn: "",
                        value: data.formDetails.numberOfHoles
                    },
                    {
                        name: "孔位置",
                        nameEn: "",
                        value: data.formDetails.positionOfHole
                    },
                    {
                        name: "孔形状",
                        nameEn: "",
                        value: data.formDetails.shapeOfHole
                    },
                    {
                        name: "孔大小",
                        nameEn: "",
                        value: data.formDetails.sizeOfHole
                    },
                    {
                        name: "分布",
                        nameEn: "",
                        value: data.formDetails.distributionOfHoles
                    },

                ]
            }

            // 蕨类植物孢子
            if (data.classification == "蕨类植物孢子") {
                list = [

                    {
                        name: "离散形式",
                        nameEn: "Discrete version",
                        value: data.formDetails.discreteVersion
                    },
                    {
                        name: "形状",
                        nameEn: "shape",
                        value: data.formDetails.shape
                    },
                    {
                        name: "赤道轮廓",
                        nameEn: "Discrete version",
                        value: data.formDetails.equatorialContour
                    },
                    {
                        name: "直径",
                        nameEn: "shape",
                        value: data.formDetails.diameter
                    },
                    {
                        name: "轮廓线",
                        nameEn: "",
                        value: data.formDetails.contourLine
                    },
                    {
                        name: "外壁厚度",
                        nameEn: "",
                        value: data.formDetails.outerWallThickness
                    },
                    {
                        name: "厚度 (微米)",
                        nameEn: "",
                        value: data.formDetails.thickness
                    },
                    {
                        name: "表面纹饰",
                        nameEn: "",
                        value: data.formDetails.surfaceOrnamentation
                    },
                    {
                        name: "远极面纹饰",
                        nameEn: "",
                        value: data.formDetails.distalFace
                    },
                    {
                        name: "近极面纹饰",
                        nameEn: "",
                        value: data.formDetails.proximalFace
                    },
                    {
                        name: "环",
                        nameEn: "",
                        value: data.formDetails.zona
                    },
                    {
                        name: "周壁",
                        nameEn: "",
                        value: data.formDetails.perisporium
                    },
                    {
                        name: "萌发口器",
                        nameEn: "aperture",
                        value: data.formDetails.aperture
                    },

                ];
                if (data.formDetails.equatorialContour.indexOf("三角形") != -1) {
                    list = [...list, {
                        name: "三边状态",
                        nameEn: "",
                        value: data.formDetails.threeSidesState
                    }, {
                        name: "角部特征",
                        nameEn: "",
                        value: data.formDetails.featureOfCorner

                    }]
                }

                if (data.formDetails.surfaceOrnamentation.indexOf("网穴") != -1) {
                    list = [...list, {
                        name: "网眼",
                        nameEn: "",
                        value: data.formDetails.lumina

                    }, {
                        name: "网脊",
                        nameEn: "",
                        value: data.formDetails.muri

                    }, {
                        name: "分布",
                        nameEn: "",
                        value: data.formDetails.wireSideDistribute

                    }]
                }

                if (data.formDetails.zona.indexOf("具环") != -1) {
                    list = [...list, {
                        name: "形状",
                        nameEn: "",
                        value: data.formDetails.shapeOfZonales

                    }, {
                        name: "具环宽度",
                        nameEn: "",
                        value: data.formDetails.widthOfZonales

                    }, {
                        name: "具环厚度",
                        nameEn: "",
                        value: data.formDetails.thicknessOfZonales

                    }, {
                        name: "纹饰",
                        nameEn: "",
                        value: data.formDetails.sculpture

                    }]
                }

                if (data.formDetails.aperture.indexOf("缝") != -1) {
                    list = [...list, {
                        name: "缝数量",
                        nameEn: "",
                        value: data.formDetails.numberOfSutura
                    }, {
                        name: "缝长度",
                        nameEn: "",
                        value: data.formDetails.lengthOfSutura
                    }, {
                        name: "缝开裂程度",
                        nameEn: "",
                        value: data.formDetails.degreeOfCrack
                    }, {
                        name: "缝状态",
                        nameEn: "",
                        value: data.formDetails.stateOfSutura
                    }, {
                        name: "唇",
                        nameEn: "",
                        value: data.formDetails.lip
                    }]
                }
            }

            // 裸子植物花粉
            if (data.classification == "裸子植物花粉") {
                list = [
                    {
                        name: "离散形式",
                        nameEn: "Discrete version",
                        value: data.formDetails.discreteVersion
                    },
                    {
                        name: "表面纹饰",
                        nameEn: "",
                        value: data.formDetails.surfaceOrnamentation
                    },
                    {
                        name: "囊",
                        nameEn: "",
                        value: data.formDetails.saccus
                    },
                    {
                        name: "萌发口器",
                        nameEn: "aperture",
                        value: data.formDetails.aperture
                    },
                ];

                if (data.formDetails.saccus.indexOf("单囊亚类") != -1) {
                    list = [...list, {
                        name: "囊宽度",
                        nameEn: "",
                        value: data.formDetails.widthOfSaccus
                    }]
                }

                if (data.formDetails.saccus.indexOf("双囊亚类") != -1) {
                    list = [...list, {
                        name: "栉",
                        nameEn: "",
                        value: data.formDetails.widthOfSaccus
                    }, {
                        name: "气囊大小",
                        nameEn: "",
                        value: data.formDetails.sizeOfGasbag
                    }, {
                        name: "气囊位置",
                        nameEn: "",
                        value: data.formDetails.positionOfGasbag
                    }]
                }

                if (data.formDetails.saccus.indexOf("多囊亚类") != -1) {
                    list = [...list, {
                        name: "气囊数量",
                        nameEn: "",
                        value: data.formDetails.numberOfGasbag
                    }]
                }

                if (data.formDetails.aperture.indexOf("沟") != -1) {
                    list = [...list, {
                        name: "沟数量",
                        nameEn: "",
                        value: data.formDetails.numberOfGroove
                    }, {
                        name: "沟位置",
                        nameEn: "",
                        value: data.formDetails.positionOfGroove
                    }, {
                        name: "类型",
                        nameEn: "",
                        value: data.formDetails.typeOfGroove
                    }, {
                        name: "沟长度",
                        nameEn: "",
                        value: data.formDetails.lengthOfGroove
                    }, {
                        name: "沟宽度",
                        nameEn: "",
                        value: data.formDetails.widthOfGroove
                    }, {
                        name: "伸达两极",
                        nameEn: "",
                        value: data.formDetails.reachToThePoles
                    }, {
                        name: "两端",
                        nameEn: "",
                        value: data.formDetails.twoEndsOfGroove
                    }, {
                        name: "沟膜",
                        nameEn: "",
                        value: data.formDetails.colpusMembrane
                    }, {
                        name: "沟间",
                        nameEn: "",
                        value: data.formDetails.intercolpar
                    }, {
                        name: "沟边缘",
                        nameEn: "",
                        value: data.formDetails.costaColpi
                    }]
                }
            }

            // 被子植物花粉
            if (data.classification == "被子植物花粉") {
                list = [

                    {
                        name: "离散形式",
                        nameEn: "Discrete version",
                        value: data.formDetails.discreteVersion
                    },
                    {
                        name: "形状",
                        nameEn: "shape",
                        value: data.formDetails.shape
                    },
                    {
                        name: "侧面轮廓",
                        nameEn: "",
                        value: data.formDetails.profile
                    },
                    {
                        name: "极轴长度",
                        nameEn: "",
                        value: data.formDetails.gzlenth
                    },
                    {
                        name: "赤道轮廓",
                        nameEn: "Discrete version",
                        value: data.formDetails.equatorialContour
                    },
                    {
                        name: "轮廓线",
                        nameEn: "",
                        value: data.formDetails.contourLine
                    },
                    {
                        name: "外壁厚度",
                        nameEn: "",
                        value: data.formDetails.outerWallThickness
                    },
                    {
                        name: "厚度 (微米)",
                        nameEn: "",
                        value: data.formDetails.thickness
                    },
                    {
                        name: "表面纹饰",
                        nameEn: "",
                        value: data.formDetails.surfaceOrnamentation
                    },
                    {
                        name: "远极面纹饰",
                        nameEn: "",
                        value: data.formDetails.distalFace
                    },
                    {
                        name: "近极面纹饰",
                        nameEn: "",
                        value: data.formDetails.proximalFace
                    },
                    {
                        name: "周壁",
                        nameEn: "",
                        value: data.formDetails.perisporium
                    },
                    {
                        name: "萌发口器",
                        nameEn: "aperture",
                        value: data.formDetails.aperture
                    }]

                if (data.formDetails.equatorialContour.indexOf("三角形") != -1) {
                    list = [...list, {
                        name: "三边状态",
                        nameEn: "",
                        value: data.formDetails.threeSidesState
                    }, {
                        name: "角部特征",
                        nameEn: "",
                        value: data.formDetails.featureOfCorner

                    }]
                }

                if (data.formDetails.surfaceOrnamentation.indexOf("网穴") != -1) {
                    list = [...list, {
                        name: "网眼",
                        nameEn: "",
                        value: data.formDetails.lumina

                    }, {
                        name: "网脊",
                        nameEn: "",
                        value: data.formDetails.muri

                    }, {
                        name: "分布",
                        nameEn: "",
                        value: data.formDetails.wireSideDistribute

                    }]
                }


                if (data.formDetails.aperture.indexOf("沟") != -1) {
                    list = [...list, {
                        name: "孔数量",
                        nameEn: "",
                        value: data.formDetails.numberOfHoles
                    }, {
                        name: "孔位置",
                        nameEn: "",
                        value: data.formDetails.positionOfHole
                    }, {
                        name: "孔形状",
                        nameEn: "",
                        value: data.formDetails.shapeOfHole
                    }, {
                        name: "孔大小",
                        nameEn: "",
                        value: data.formDetails.sizeOfHole
                    }, {
                        name: "孔径",
                        nameEn: "",
                        value: data.formDetails.pore
                    }, {
                        name: "孔环",
                        nameEn: "",
                        value: data.formDetails.annulus
                    }, {
                        name: "环宽度",
                        nameEn: "",
                        value: data.formDetails.widthOfAnnulus
                    }, {
                        name: "孔缘",
                        nameEn: "",
                        value: data.formDetails.peritreme
                    }, {
                        name: "孔盖",
                        nameEn: "",
                        value: data.formDetails.opercule
                    }, {
                        name: "孔庭",
                        nameEn: "",
                        value: data.formDetails.kongTing
                    }, {
                        name: "分布",
                        nameEn: "",
                        value: data.formDetails.distributionOfHoles
                    }, {
                        name: "孔间",
                        nameEn: "",
                        value: data.formDetails.mesoporium
                    }, {
                        name: "外壁在孔处",
                        nameEn: "",
                        value: data.formDetails.atTheHole
                    }]
                }


                if (data.formDetails.aperture.indexOf("沟") != -1) {
                    list = [...list, {
                        name: "沟数量",
                        nameEn: "",
                        value: data.formDetails.numberOfGroove
                    }, {
                        name: "沟位置",
                        nameEn: "",
                        value: data.formDetails.positionOfGroove
                    }, {
                        name: "类型",
                        nameEn: "",
                        value: data.formDetails.typeOfGroove
                    }, {
                        name: "沟长度",
                        nameEn: "",
                        value: data.formDetails.lengthOfGroove
                    }, {
                        name: "沟宽度",
                        nameEn: "",
                        value: data.formDetails.widthOfGroove
                    }, {
                        name: "伸达两极",
                        nameEn: "",
                        value: data.formDetails.reachToThePoles
                    }, {
                        name: "两端",
                        nameEn: "",
                        value: data.formDetails.twoEndsOfGroove
                    }, {
                        name: "沟膜",
                        nameEn: "",
                        value: data.formDetails.colpusMembrane
                    }, {
                        name: "沟间",
                        nameEn: "",
                        value: data.formDetails.intercolpar
                    }, {
                        name: "沟边缘",
                        nameEn: "",
                        value: data.formDetails.costaColpi
                    }]
                }
            }

            return list
        },
        handleCurrentChange(e) {
            this.init(e)
        },
        async init(pageNum = 1) {
            let { data } = await formApi.getTemplate(this.option, pageNum)
            this.total = data.total;
            this.datalist = data.records
        }
    },
    watch: {
        formList(newVal, oldVal) {

        }
    },
    created() {
        // this.$store.watch(({ common: { formList } }, getters) => {
        //     this.total = formList.total;
        //     this.datalist = formList.records
        // })
        this.option = this.$route.params;
        this.init()
    },

}
</script>

<style lang="scss" scoped>
.formlist {
    width: 1200px;
    margin: 0 auto;
    height: initial;
    padding-top: 30px;
    padding-bottom: 80px;
    box-sizing: border-box;

}

.list {
    display: flex;
    margin-top: 30px;
    height: 130px;

    img {
        // margin-top: 5px;
        height: 130px;
        width: 130px;
        border-radius: 12px;
    }

    .msg {
        width: 750px;
        margin-left: 20px;
        flex-flow: column;


        &-father {
            font-size: 14px;
            font-weight: 600;
            height: 15%;
            margin-bottom: 1.2%;

            div {
                width: 30%;
            }
        }

        &-son {
            font-size: 12px;
            // margin-top: 10px;
            // height: 83%;

            span {
                display: inline-block;
                width: 25%;
                height: 20px;
                // margin-bottom: 5px;
            }
        }
    }
}

.page {
    margin-top: 40px;

    // background: #f8f8f8;
    ::v-deep .el-pagination {
        .btn-prev {
            background: #fff;
        }

        .btn-next {
            background: #fff;
        }

        .el-pager {
            .number {
                background: #fff;
                color: #2440b3;
            }

            .number.active {
                background: #2440b3;
                color: #fff;
            }
        }
    }
}
</style>